import React from "react"
import { Container, Row, Col } from "reactstrap"
import { loadStripe } from "@stripe/stripe-js"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ButtonPrimary } from "../components/utils/Buttons"
import { D4, H3, P } from "../components/utils/Typography"

export default class Hire extends React.Component {
  render() {
    const stripePromise = loadStripe(
      "pk_live_kbHsT8UsKKrOWz0R3a4qaFe7008Jf1L6vd"
    )

    const handleClick = async e => {
      const stripe = await stripePromise
      const { error } = await stripe
        .redirectToCheckout({
          items: [{ sku: "sku_HAgRhNIZLAoiQ8", quantity: 1 }],
          successUrl: "https://www.jobsgoremote.com/success/",
          cancelUrl: "https://www.jobsgoremote.com/error/",
        })
        .then(function(result) {
          console.log(result)
          console.log(error)
        })
    }

    return (
      <Layout>
        <SEO title="Post a Job" />
        <Container style={{ minHeight: "80vh", padding: "20px 20px 40px" }}>
          <Row>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <H3 margin="60px 0 20px">Proceed to Checkout.</H3>
                  <P>
                    <ul>
                      <li>After payment, we will review your listing.</li>
                      <li>We will publish your listing within 48 hours.</li>
                      <li>
                        Please email hello@jobsgoremote.com if you have any
                        questions.
                      </li>
                    </ul>
                  </P>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <D4
                    margin="60px 0 10px"
                    fontWeight="600"
                    style={{ color: "#a1a1a1" }}
                  >
                    <span style={{ fontWeight: "700" }}>Step 1.</span> Fill out
                    Job Information
                  </D4>
                </Col>
                <Col lg="12">
                  <D4 margin="30px 0 20px" fontWeight="600">
                    <span style={{ color: "#0022e7", fontWeight: "700" }}>
                      Step 2.
                    </span>{" "}
                    Submit Payment ($300)
                  </D4>
                </Col>
                <Col lg="12">
                  <ButtonPrimary
                    role="link"
                    onClick={handleClick}
                    width="250px"
                  >
                    Checkout
                  </ButtonPrimary>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
